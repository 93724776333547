import React, { useContext } from 'react';
import { Context } from '../../store/modalContext/store';
import PasswordField from './PasswordField';
import {
	// onLogin,
	onSendResetPasswordEmail,
} from '../../api/loginData';
import './style.scss';
import { useAppSelector } from '../../store/store';
import {
	updateEmail,
	selectedEmailSelector,
	handleUrl,
	forgotPasswordSelector,
	showForgotPasswordSelector,
	setShowForgotPassword,
	showPasswordSelector,
	setShowPassword,
	updateEmailToken,
	allowRegisterSelector,
	showRegisterSelector,
	isTokenValidSelector,
	errorSelector,
	setShowRegister,
	setShowLogin,
	loginDataSelector,
	removeChangePasswordDetails,
	resetLoginData,
	resetSomeLoginData,
	setForgotPasswordToken,
} from '../../store/login/loginSlice';
import loginFB from '../../assets/loginFB.png';
import { useDispatch } from 'react-redux';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import { loginTexts } from '../../business/loginHelper';
import {
	api,
	useGetJoinDetailsMutation,
	useLoginMutation,
	useResetPasswordMutation,
	useSendLoginEmailMutation,
	useSendResetPasswordEmailMutation,
	useVerifyJoinTokenMutation,
} from '../../store/api/api';
import { Alert, Theme, styled, FormGroup, Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import LoginScreenWrapper from './LoginScreenWrapper';
import LoginInputField from './LoginInputField';
import { LoadingButton } from '@mui/lab';
import { APP_TYPES } from '@toriosoftware/torioshared/dist/config';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
	Header,
	LowerHeader,
	UnderInputText,
	BackText,
} from './LoginStyledComponents';
import { useHistoryNav } from '../../hooks/useHistoryNav';

const truncateURLAfterLogin = (url) => {
	const loginIndex = url.indexOf('/login');
	return loginIndex !== -1
		? url.substring(0, loginIndex + '/login'.length)
		: url;
};

const Login = ({ withToken }: any) => {
	//@ts-ignore
	const [modalState] = useContext(Context);
	const dispatch = useDispatch();
	let params = useParams();
	const [searchParams] = useSearchParams();
	let emailQuery = searchParams.get('email');
	let token = '';
	const location = useLocation();
	const history = useHistoryNav();

	const [password, setPassword] = React.useState('');
	const [isToken, setIsToken] = React.useState(false);
	const [passwordConfirm, setPasswordConfirm] = React.useState('');
	const [firstName, setFirstName] = React.useState('');
	const [lastName, setLastName] = React.useState('');
	const [getJoinDetails] = useGetJoinDetailsMutation();
	const [sendResetPasswordEmail] = useSendResetPasswordEmailMutation();
	const [resetPassword] = useResetPasswordMutation();
	const [sendEmailLogin] = useSendLoginEmailMutation();
	const [login, loginStatus] = useLoginMutation();
	const [agreeToTerms, setAgreeToTerms] = React.useState(true);
	const { search } = useLocation();

	let email = useAppSelector(selectedEmailSelector);
	const forgotPasswordToken = ''; //= useAppSelector(forgotPasswordSelector);
	const showForgotPassword = false; //useAppSelector(showForgotPasswordSelector);
	const showPassword = false; // useAppSelector(showPasswordSelector);
	const isTokenValid = useAppSelector(isTokenValidSelector);
	const loginData = useAppSelector(loginDataSelector);
	let error = useAppSelector(errorSelector);

	let showRegister = false;
	let allowRegister = false;
	// let showRegister = useAppSelector(showRegisterSelector);
	// let allowRegister = true; //useAppSelector(allowRegisterSelector);

	const changeUrl = () => {
		const currentPath = location.pathname;
		const truncatedPath = truncateURLAfterLogin(currentPath);
		history(truncatedPath, { replace: true });
	};

	const onEmailLogin = async (tokenPara) => {
		try {
			const inputData = {
				email: emailQuery,
				password: tokenPara,
				phone: '',
				loginType: APP_TYPES.session.loginType.Email,
				sessionType: APP_TYPES.session.authType.Cookie,
				loginAsCompanyAdmin: 'yes',
				platform: APP_TYPES.platform.TorioWeb,
				countryPhoneCode: '',
				loginToken: token,
				isRegister: showRegister,
				firstName: firstName,
				lastName: lastName,
				agreeToTerms: agreeToTerms,
				modal: modalState,
				joinPlaceToken: '',
			};

			if (emailQuery && tokenPara) {
				let result = await login({ ...inputData, modal: modalState }).unwrap();
				if (result) {
					changeUrl();
					if (result.success) {
						window.location.reload();
					}
				}
			}
		} catch (ex) {}
	};

	const onEmailChange = (value) => {
		dispatch(updateEmail(value));
	};

	React.useEffect(() => {
		(async () => {
			if (params && params.token) {
				onEmailLogin(params.token);
			}
		})();
	}, [params]);

	const onSendEmail = async () => {
		let res = await sendEmailLogin({
			email,
			modal: modalState,
		}).unwrap();
		if (res) {
			console.log('res', res);
			if (res.success) {
				dispatch(updateEmail(''));
			}
		}
	};

	return (
		<LoginScreenWrapper>
			<Header>
				{loginTexts.headerText(
					forgotPasswordToken,
					showForgotPassword,
					showRegister
				)}
			</Header>

			<div>
				<LowerHeader>
					{loginTexts.lowerHeaderText(
						forgotPasswordToken,
						showForgotPassword,
						showRegister
					)}
				</LowerHeader>
			</div>

			{loginData.placeName && loginData.isTokenValid ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginTop: 25,
					}}
				>
					{loginData && loginData.image ? (
						<img
							src={loginData.image}
							alt="logo"
							style={{
								width: 100,
								height: 100,
								borderRadius: 10,
								margin: 'auto',
							}}
						/>
					) : null}
					<p style={{ textAlign: 'center' }}>
						You have been invited to join <b>{loginData.placeName}</b>.
					</p>
				</div>
			) : null}

			{allowRegister ? (
				<div
					style={{
						margin: 'auto',
						width: '100%',
						textAlign: 'center',
						marginBottom: -51,
						marginTop: 72,
					}}
				>
					<ButtonGroup
						color="primary"
						aria-label="medium secondary button group"
					>
						<LoadingButton
							loading={loginStatus.isLoading}
							onClick={() => {
								dispatch(setShowLogin());
							}}
							variant={showRegister ? 'outlined' : 'contained'}
							key="login"
						>
							Continue to
						</LoadingButton>
						<LoadingButton
							loading={loginStatus.isLoading}
							onClick={() => dispatch(setShowRegister())}
							variant={showRegister ? 'contained' : 'outlined'}
							key="register"
						>
							Register
						</LoadingButton>
					</ButtonGroup>
				</div>
			) : null}

			{isToken ? (
				<div
					style={{
						margin: 'auto',
						textAlign: 'center',
						position: 'relative',
						top: -38,
					}}
				>
					<LoadingButton
						loading={loginStatus.isLoading}
						onClick={() => {
							dispatch(resetLoginData());
						}}
						variant={'outlined'}
						key="login"
					>
						Login with different email
					</LoadingButton>
				</div>
			) : null}

			<div className="login-main-wrapper__input-main-wrapper">
				{loginStatus.isLoading ? null : (
					<LoginInputField
						onChange={(value) => onEmailChange(value)}
						value={email}
						isDisabled={forgotPasswordToken || isTokenValid ? true : false}
						withEnvelope={true}
						placeholder={'Email'}
					/>
				)}

				{showRegister ? (
					<div>
						<LoginInputField
							onChange={setFirstName}
							value={firstName}
							isDisabled={false}
							withEnvelope={false}
							placeholder={'First name'}
						/>

						<LoginInputField
							onChange={setLastName}
							value={lastName}
							isDisabled={false}
							withEnvelope={false}
							placeholder={'Last name'}
						/>
					</div>
				) : null}

				{false &&
				(!showForgotPassword ||
					forgotPasswordToken ||
					(showRegister && !showForgotPassword)) ? (
					<div>
						<PasswordField
							// extraMargin={forgotPasswordToken || showRegister ? 36 : 0}
							extraMargin={36}
							label={'Password'}
							setShowPassword={() => dispatch(setShowPassword())}
							setPassword={setPassword}
							showPassword={showPassword}
							password={password}
						/>
						{forgotPasswordToken || showRegister ? (
							<PasswordField
								extraMargin={null}
								label={'Confirm password'}
								setShowPassword={() => dispatch(setShowPassword())}
								setPassword={setPasswordConfirm}
								showPassword={showPassword}
								password={passwordConfirm}
							/>
						) : null}
					</div>
				) : null}

				{/* <UnderInputText
					onClick={() => {
						dispatch(setShowForgotPassword(!showForgotPassword));
					}}
				>
					{loginTexts.underInputText(
						forgotPasswordToken,
						showForgotPassword,
						showRegister
					)}
				</UnderInputText> */}
			</div>

			{showRegister ? (
				<label
					htmlFor="check"
					style={{
						display: 'flex',
						alignItems: 'center',
						position: 'relative',
						top: -15,
					}}
				>
					<p>
						By joining you agree to the{' '}
						<a
							style={{
								color: 'white',
								textDecoration: 'none',
								fontWeight: 'bolder',
							}}
							rel="noreferrer"
							target="_blank"
							href="https://admin.torio.is/home/terms"
						>
							Terms and Conditions
						</a>{' '}
						and{' '}
						<a
							style={{
								color: 'white',
								textDecoration: 'none',
								fontWeight: 'bolder',
							}}
							rel="noreferrer"
							target="_blank"
							href="https://admin.torio.is/home/privacy"
						>
							Privacy Policy
						</a>
						.
					</p>
				</label>
			) : null}

			<div
				style={{
					display: 'flex',
					paddingTop: 80,
					flexDirection: 'column',
					justifyContent: 'center',
					margin: 'auto',
					width: '100%',
				}}
			>
				<LoadingButton
					onClick={async () => await onSendEmail()}
					variant="contained"
					loading={loginStatus.isLoading}
					size="large"
					style={{
						color: 'white',
						width: 270,
						padding: 10,
						margin: 'auto',
					}}
				>
					{loginTexts.buttonText(
						forgotPasswordToken,
						showForgotPassword,
						showRegister
					)}
				</LoadingButton>
				{/* display: 'flex', */}

				{!loginStatus.isLoading ? (
					<div style={{ justifyContent: 'center', marginTop: 10 }}>
						<a
							href={'/auth/facebook'}
							style={{
								backgroundColor: '#1977f3',
								width: 270,
								textDecoration: 'none',
								height: 50,
								cursor: 'pointer',
								borderRadius: 5,
								display: 'flex',
								justifyContent: 'center',
								textAlign: 'center',
								alignItems: 'center',
								fontWeight: 'bold',
								color: 'white',
								margin: 'auto',
							}}
						>
							<FacebookIcon
								style={{ fontSize: 26, color: 'white', marginRight: 10 }}
							/>
							<span style={{ width: 170 }}>Login with Facebook</span>
						</a>
					</div>
				) : null}

				{/* {!loginStatus.isLoading ? (
					<div
						id={'google-login'}
						style={{ justifyContent: 'center', marginTop: 10 }}
					>
						<a
							href={'/auth/google'}
							style={{
								backgroundColor: '#4285f4',
								width: 270,
								textDecoration: 'none',
								height: 50,
								cursor: 'pointer',
								borderRadius: 5,
								display: 'flex',
								justifyContent: 'center',
								textAlign: 'center',
								alignItems: 'center',
								fontWeight: 'bold',
								color: 'white',
								margin: 'auto',
							}}
						>
							<GoogleIcon
								style={{ fontSize: 26, color: 'white', marginRight: 10 }}
							/>
							<span style={{ width: 170 }}>
								Login with Google (coming soon)
							</span>
						</a>
					</div>
				) : null} */}

				{forgotPasswordToken ? (
					<a
						onClick={() => {
							dispatch(removeChangePasswordDetails());
						}}
						style={{ textDecoration: 'none', margin: 'auto' }}
						href="/Login"
					>
						<Button
							variant="contained"
							size="large"
							style={{
								color: 'white',
								width: 270,
								backgroundColor: 'red',
								padding: 10,
								margin: 'auto',
								marginTop: 40,
							}}
						>
							Don't change password
						</Button>
					</a>
				) : null}

				<BackText
					href="https://admin.torio.is"
					style={{
						display: 'block',
						textDecoration: 'none',
						width: '140px',
						margin: 'auto',
						color: 'white',
						marginTop: 50,
						textAlign: 'center',
					}}
				>
					Back to front page
				</BackText>

				<div style={{ marginTop: 40 }}>
					{error ? (
						<Alert style={{ marginBottom: 10 }} severity="error">
							{error}
						</Alert>
					) : null}
					{!forgotPasswordToken ? (
						<Alert severity="info">
							This login page is for company users only. You need to be invited
							to login here.
						</Alert>
					) : null}
				</div>
			</div>

			<div className="login-main-wrapper__cotnent-or"></div>
		</LoginScreenWrapper>
	);
};

export default Login;

// React.useEffect(() => {
// 	// dispatch(handleUrl());
// 	setTimeout(() => {
// 		if (token) {
// 			getJoinDetails({ token, modal: modalState });
// 			setIsToken(true);
// 		}
// 	}, 1000);
// }, [token]);

// React.useEffect(() => {
// 	// dispatch(handleUrl());
// 	if (!token && search) {
// 		const params = new URLSearchParams(search);
// 		const emailTemp = params.get('email');
// 		const resetPassTokenTemp = params.get('resetpassword');
// 		if (emailTemp && resetPassTokenTemp) {
// 			dispatch(setForgotPasswordToken(resetPassTokenTemp));
// 			dispatch(updateEmail(emailTemp));
// 		}
// 	}
// }, [search, token]);

// const handleVerifyJoinToken = async (token) => {
// 	if (token) {
// 		let result = await verifyJoinToken(token);
// 		console.log('result: ', result);
// 	}
// };

// React.useEffect(() => {
// 	handleVerifyJoinToken(token);
// }, [token]);
