import { useCouponMutation, useManageCouponMutation } from '../../store/api/api';
import { useForm } from 'react-hook-form';
import useManageHook from '../../components/hooks/useManageHook';
import { joiResolver } from '@hookform/resolvers/joi';
import {
	couponInterface,
	couponSchema,
	couponInitialized,
} from '@toriosoftware/torioshared/dist/coupon/Coupon';
import MainInputController from '../../components/inputs/MainInputController';
import FormContainer from '../../components/containers/FormContainer';
import PageContainer from '../../components/containers/PageContainer';
import { GET_ROUTE_COMPONENT } from '../../config/config';
import {
	IMAGE_TYPES,
	ROUTE_NAMES,
	ROUTE_TYPES,
} from '@toriosoftware/torioshared/dist/config';
import MainSwitchController from '../../components/switch/MainSwitchController';
import SubHeader from '../../components/texts/SubHeader';
import MainSwitch from '../../components/switch/MainSwitch';
import React from 'react';
import MainImageLinksController from '../../components/images/MainImageLinksController';

const Coupon = ({ user }) => {
	// State
	const [coupon] = useCouponMutation();
	const [manageCoupon] = useManageCouponMutation();
	const [isPercentage, setIsPercentage] = React.useState(true);

	// POST DATA
	const {
		formState: { isDirty },
		handleSubmit,
		control,
		reset,
		watch,
		setValue,
	} = useForm<couponInterface>({
		defaultValues: couponInitialized,
		shouldFocusError: true,
		mode: 'onChange',
			// resolver: joiResolver(couponSchema),
	});

	// GET DATA
	const {
		modelState,
		setIsLoaded,
		isLoaded,
		history,
		isNew,
		id,
		data,
		csrfToken,
	} = useManageHook({
		isLoadedInitial: false,
		retrieveData: [
			GET_ROUTE_COMPONENT(ROUTE_NAMES.coupon, user)?.data?.get(coupon, reset),
		],
		user: user,
	});

	const imageLink = watch('imageLink');

	return (
		<PageContainer breadPlaceholder={'Coupon'} header={'Coupon'}>
			<FormContainer
				csrfToken={csrfToken}
				isDirty={isDirty}
				modal={modelState}
				handleSubmit={handleSubmit}
				showBack={true}
				onSubmitFunction={manageCoupon}
				onRemoveFunction={manageCoupon}
				linkAfterDelete={'coupons'}
				title={'Coupon'}
				linkAfterSuccess={isNew ? ROUTE_TYPES[ROUTE_NAMES.coupons].route : ''}
				customData={{ id }}
				isLoaded={isLoaded}
				goBackDepth={0}
				history={history}
				hideRemove={isNew ? true : false}
			>

				<MainInputController
					control={control}
					label={'Name'}
					identifier={'name'}
					md={12}
				/>

				<MainInputController
					control={control}
					label={'Description'}
					rows={'2'}
					identifier={'description'}
					md={12}
				/>

				<MainSwitch
					key={523421}
					isBig={true}
					value={isPercentage}
					label={'Is percentage discount'}
					onChange={(e) => {
						let newPercentage = !isPercentage;
						setIsPercentage(newPercentage);
						setValue('percentage', 0);
						setValue('amount', 0);
						setValue('originalAmount', 0);
					}}
					identifier={'isPercentage'}
				/>
				
				<MainInputController
					control={control}
					label={'Discount percentage'}
					type={'number'}
					hideInput={!isPercentage}
					identifier={'percentage'}
					md={12}
					options={{
						max: 100,
						min: 0,
					}}
					endText={'%'}
				/>

				<MainInputController
					control={control}
					label={'Old price'}
					hideInput={isPercentage}
					type={'number'}
					identifier={'originalAmount'}
					md={6}
					options={{
						max: 30000,
						min: 0,
					}}
					endText={' kr'}
				/>
				
				<MainInputController
					control={control}
					label={'New price'}
					hideInput={isPercentage}
					type={'number'}
					identifier={'amount'}
					md={6}
					options={{
						max: 30000,
						min: 0,
					}}
					endText={' kr'}
				/>

				<MainSwitchController
					key={21412}
					control={control}
					label={'Display below main card in Barhopp'}
					md={12}
					identifier={'isMain'}
				/>


				<SubHeader text={'Is available on'} />

				<MainInputController
					control={control}
					label={'From'}
					type={'text'}
					identifier={'from'}
					md={6}
				/>

				<MainInputController
					control={control}
					label={'To'}
					type={'text'}
					identifier={'to'}
					md={6}
				/>

				{
					['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
						return(
							<MainSwitchController
								key={index}
								control={control}
								label={day}
								md={3}
								identifier={day.toLowerCase() }
							/>
						)
					})
				}

				<MainImageLinksController
					control={control}
					maxSize={IMAGE_TYPES['coupon'].maxSize}
					text={'Upload an image of this coupon'}
					initialFiles={imageLink}
					identifier={'imageLink'}
					setValue={setValue}
					compression={IMAGE_TYPES['coupon'].compression}
					filesLimit={1}
				/>
				
			</FormContainer>
		</PageContainer>
	);
};

export default Coupon;
